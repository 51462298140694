<template>
  <div id="helpPage">
    <div class="helpBox">
      <div class="helpContainer row">
        <div class="navList">
            <div class="helpTitle">帮助中心</div>
          <el-menu class="el-menu-vertical-demo" router>
            <el-submenu index="1" class="user none">
              <template slot="title">
                <span slot="title">新手指南</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/" class="userList none">注册登录</el-menu-item>
                <el-menu-item index="/" class="addUser none">下单流程</el-menu-item>
                <el-menu-item index="/" class="addUser none">多商比价</el-menu-item>
                <el-menu-item index="/" class="addUser none">发布需求</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="2" class="userPool none">
              <template slot="title">
                <span slot="title">支付方式</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/addCustomer" class="addCustomer none">支付宝支付</el-menu-item>
                <el-menu-item index="/customerList" class="customerList none">微信支付</el-menu-item>
                <el-menu-item index="/customerList" class="customerList none">银行转账</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="3" class="memberManage none">
              <template slot="title">
                <span slot="title">配送方式</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/member_list" class="member none">配送方式</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">配送范围及运费</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">验货与签收</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4" class="memberManage none">
              <template slot="title">
                <span slot="title">退款售后</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/member_list" class="member none">退款说明</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">返修/退换货</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">售后政策</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">售后服务</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">价格保护</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5" class="memberManage none">
              <template slot="title">
                <span slot="title">关于我们</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/memberReport" class="memberPic none">关于孔雀</el-menu-item>
                <el-menu-item index="/memberReport" class="memberPic none">联系我们</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="box">
            
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
#helpPage {
}

.helpTitle {
  height: 32px;
  padding-left: 22px;
  line-height: 32px;
  color: #fff;
  font-size: 16px;
  background-color: #0db168;
}

.helpBox {
  width: 100%;
  padding: 50px 0 50px;
  background: #f5f5f5;
}

/deep/ .helpContainer {
  width: 1200px;
  min-height: 360px;
  margin: 0 auto;
  .el-submenu__title {
    padding-left: 25px;
    background-color: #f5f5f5;
    margin-bottom: 3px;
  }
  .el-menu-item-group__title {
    padding: 0 !important;
  }
  .el-submenu .el-menu-item {
    min-width: 0 !important;
    padding: 0 0 0 25px !important ;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 45px !important;
    line-height: 45px !important;
  }
  .el-submenu__title i {
    font-weight: bold !important;
  }
  .el-submenu__title:hover {
    background-color: #c7f5e0 !important;
    // opacity: 0.25;
    color: #fff;
  }
}

.navList {
  width: 170px;
  height: 100%;
  padding-bottom: 150px;
  margin-right: 20px;
  background: #fff;
  border: 1px solid #0db168;
}

.box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: 500px;
}
</style>


